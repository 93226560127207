<template>
  <section class="create_comments">
    <v-form>
      <v-container>
        <h1>Change Password</h1>
        <v-row>
          <v-col
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="formData.new_password"
                type="password"
                label="New Password"
                outlined
            ></v-text-field>
          </v-col>

          <v-col
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="formData.confirm_password"
                label="Confirm Password"
                type="password"
                outlined
            ></v-text-field>
          </v-col>

          <v-btn
              :loading="loading_form"
              :disabled="loading_form"
              color="blue-grey"
              outlined
              class="ma-2 white--text"
              @click="change_the_password"
          >
            Add
            <v-icon
                right
                dark
            >
              mdi-cloud-upload
            </v-icon>
          </v-btn>

        </v-row>
      </v-container>
    </v-form>
  </section>
</template>

<script>
import {change_password} from "@/api/admin";

export default {
  data: () => ({
    loading_form: false,
    formData: {
      new_password: '',
      confirm_password: '',
    },
  }),
  methods: {
    /**
     * login method
     * method invoked when we click on login button
     */
    change_the_password() {
      this.loading_form = true;
      if(this.formData.new_password !== this.formData.confirm_password){
        alert('Password Not Matched');
        this.loading_form = false;
      }else {
        change_password(this.formData)
            .then(()  => {
              this.loading_form = false;
              alert('password changed');
              localStorage.removeItem('token')
              this.$router.push('/');
            }).catch(() => {
        })
      }
    }
  }
}
</script>